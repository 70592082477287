// @flow

import { useState, useEffect } from 'react'

declare var globalThis: any

const getWidth = () =>
  globalThis?.innerWidth ||
  globalThis?.document?.documentElement?.clientWidth ||
  globalThis?.document?.body?.clientWidth

export function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth())

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId)
      // change width from the state object after 150 milliseconds
      timeoutId = window.setTimeout(() => setWidth(getWidth()), 150)
    }
    // set resize listener
    window.addEventListener('resize', resizeListener)

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return width
}
