// @flow
import styled from '@emotion/styled'
import * as React from 'react'

import { colors, media } from '@toggl/style'
import { Form, hooks, text } from '@toggl/ui'

type WrapperProps = {|
  +className?: string,
  +entries: $ReadOnlyArray<{| +id: string, +name: string |}>,
  +header: string,
  +initialActive?: string,
  +onFilter: (string) => void,
|}

export default function Wrapper({
  className,
  entries,
  header,
  initialActive,
  onFilter,
}: WrapperProps) {
  const [active, setActive] = React.useState('')

  React.useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  const onEntrySelected = (id) => {
    setActive(id)
    onFilter(id)
  }

  if (!hooks.useMediaQuery(media.mqbp[2])) {
    return (
      <Form.Select
        id="filter"
        name="filter"
        onChange={(evt: SyntheticEvent<>) =>
          onEntrySelected((evt.target: window.HTMLInputElement).value)
        }
        label=""
        value={active}
      >
        {entries.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Form.Select>
    )
  }

  return (
    <WrapperRoot className={className}>
      <Header>{header}</Header>
      {entries.map(({ id, name }) => (
        <Link
          key={id}
          active={id === active}
          onClick={(e) => {
            e.preventDefault()
            onEntrySelected(id)
          }}
        >
          {name}
        </Link>
      ))}
    </WrapperRoot>
  )
}

const WrapperRoot: React.ComponentType<any> = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Header = styled.div`
  ${text.paragraph4};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${colors.darkPurple};
  border-radius: 16px;
  width: 120px;
  height: 32px;
`

const Link: React.ComponentType<any> = styled.a`
  ${text.heading5};

  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  color: ${({ active, theme }) => (active ? theme.primary : colors.darkPurple)};

  border-bottom: ${({ active, theme }) =>
    active ? `1px solid ${theme.primary}` : ''};

  &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.primary};
  }
`
