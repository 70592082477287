// @flow

import type { ComponentType } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import { colors, media } from '@toggl/style'
import { defaultText, heading4, heading5, paragraph4 } from '../text'
import { Icon } from '../../index'

const Button = styled.button`
  ${defaultText};
  border-radius: 26px;
  padding: 14px 50px;
  border: 0;
  height: 44px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 150ms linear, color 150ms linear;

  ${media.mq[1]} {
    height: 52px;
  }

  ${(p) => (p.fluid ? 'width: 100%;' : '')};

  &:hover {
    background-color: ${colors.fadedPurpleAlt};
    color: ${colors.almostWhite};
  }
`

export const Primary: ComponentType<any> = styled(Button)`
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.accent};
`

const SecondaryButton: ComponentType<any> = styled(Button)`
  background-color: transparent;
  color: ${(p) => p.color || colors.darkPurple};
  padding: 0;
  height: auto;
  line-height: 1.5;

  ${media.mq[1]} {
    height: auto;
    color: ${(p) => p.color || colors.almostWhite};
  }

  &:hover {
    background-color: transparent;
    color: ${(p) => p.theme.primary};
  }
`

const CaretRight = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid
    ${(p) => (p.hovered ? p.theme.primary : p.color || colors.darkPurple)};
  margin-left: 20px;

  ${media.mq[1]} {
    border-left: 4px solid
      ${(p) => (p.hovered ? p.theme.primary : p.color || colors.almostWhite)};
  }
`

const CaretDown = styled.span`
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 20px;

  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 4px solid
    ${(p) => (p.hovered ? p.theme.primary : p.color || colors.almostWhite)};

  ${media.mq[1]} {
    border-top: 4px solid
      ${(p) => (p.hovered ? p.theme.primary : p.color || colors.almostWhite)};
  }
`

export const Secondary = ({
  children,
  caretDirection = 'right',
  color,
  ...props
}: {
  children: Node | string,
  caretDirection?: 'right' | 'down',
  onClick?: (SyntheticEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  color?: string,
}) => {
  const [hovered, setHovered] = React.useState(false)
  return (
    <SecondaryButton
      color={color}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      {children}
      {caretDirection === 'right' && (
        <CaretRight color={color} hovered={hovered} />
      )}
      {caretDirection === 'down' && (
        <CaretDown color={color} hovered={hovered} />
      )}
    </SecondaryButton>
  )
}

export const SecondaryTrack: ComponentType<any> = styled(Button)`
  background-color: ${colors.beige};
  color: ${(p) => p.theme.primary};
`

export const Alternate: ComponentType<any> = styled(Button)`
  background-color: ${colors.darkPurple};
  color: ${colors.almostWhite};
`

const StyledCircleButton: ComponentType<any> = styled(Button)`
  width: ${(p) => (p.large ? '96px' : '64px')};
  height: ${(p) => (p.large ? '96px' : '64px')};
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: ${(p) => (p.large ? '32px' : '22px')};
    transform: ${(p) => (p.side === 'right' ? 'rotate(180deg)' : '')};
  }
  background-color: ${colors.beige};

  &:hover {
    background-color: ${(p) => p.theme.primary};
  }

  ${media.mq[1]} {
    width: ${(p) => (p.large ? '96px' : '64px')};
    height: ${(p) => (p.large ? '96px' : '64px')};
    &:hover {
      background-color: ${(p) => p.theme.primary};
    }
  }
`

export const Circle = ({
  side,
  onClick,
  large,
  arrowColor,
  ...props
}: {
  side?: 'right' | 'left',
  large?: boolean,
  arrowColor?: string,
  onClick?: () => void,
}) => {
  const [hovered, setHovered] = React.useState(false)
  const theme = useTheme()
  return (
    <StyledCircleButton
      large={large}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      side={side || 'right'}
      {...props}
    >
      <Icon.ArrowBigLeft
        color={hovered ? theme.accent : arrowColor ? arrowColor : colors.pink}
      />
    </StyledCircleButton>
  )
}

export const SocialLogin: ComponentType<any> = styled.button`
  background: white;
  border: none;
  ${heading5};
  color: ${colors.darkPurple};
  width: 286px;
  display: flex;
  align-items: center;
  height: 52px;
  cursor: pointer;

  svg {
    width: 30px;
    margin-right: 5px;
  }
`

export const Toggle = ({
  onClick,
  defaultActive,
  labels = [],
  promo,
}: {
  defaultActive: 'right' | 'left',
  labels: Array<string>,
  onClick: (active: 'left' | 'right') => void,
  promo?: string,
}) => {
  const [active, setActive] = React.useState(defaultActive)
  const [hovered, setHovered] = React.useState(false)

  const onClickHandler = (pos) => {
    setActive(pos)
    setHovered(false)
    onClick(pos)
  }
  return (
    <React.Fragment>
      {promo && (
        <PromoPopup>
          <span>{promo}</span>
        </PromoPopup>
      )}
      <StyledToggleWrapper promo={promo}>
        <BgColor hovered={hovered} active={active} />
        <StyledToggleButton
          onMouseEnter={() => setHovered('left')}
          onMouseLeave={() => setHovered(false)}
          onClick={() => onClickHandler('left')}
          active={active === 'left'}
          accent={active === 'left' && hovered !== 'right'}
        >
          {labels[0]}
        </StyledToggleButton>
        <StyledToggleButton
          onMouseEnter={() => setHovered('right')}
          onMouseLeave={() => setHovered(false)}
          onClick={() => onClickHandler('right')}
          active={active === 'right'}
          accent={active === 'right' && hovered !== 'left'}
        >
          {labels[1]}
        </StyledToggleButton>
      </StyledToggleWrapper>
    </React.Fragment>
  )
}

const bgTransformMobile = ({ active }) => (active === 'left' ? 0 : 125)

const bgTransformDesktop = ({ active, hovered }) => {
  if (!hovered && active === 'left') return 0
  if (!hovered && active === 'right') return 160
  return hovered === 'left' ? 0 : 160
}

const BgColor = styled.div`
  position: absolute;
  background-color: ${(p) =>
    p.hovered ? colors.fadedPurpleAlt : p.theme.primary};
  border-radius: 26px;
  margin: 3px;
  height: 46px;
  transition: 0.4s;

  width: 140px;
  transform: translateX(${bgTransformMobile}px);
  ${media.mq[1]} {
    width: 167px;
    transform: translateX(${bgTransformDesktop}px);
  }
`

const StyledToggleButton: ComponentType<any> = styled(Button)`
  width: 130px;
  padding: 11px 45px;

  &:last-child {
    padding: 11px 30px;
  }

  ${media.mq[1]} {
    width: unset;
    padding: 11px 52px !important;
  }
  margin: 3px;

  height: 46px !important;
  background-color: transparent !important;
  outline: none;

  z-index: 1;

  color: ${({ accent, theme }) => accent && theme.accent};
`

const StyledToggleWrapper: ComponentType<any> = styled.div`
  display: flex;
  background-color: ${(p) => p.theme.accent};
  width: fit-content;
  border-radius: 26px;
`

const PromoPopup = styled.div`
  ${heading4};
  font-size: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: relative;

  span {
    text-align: center;
    color: ${colors.darkPurple};

    background-color: ${({ theme }) => theme.accent};
    width: 80px;

    height: 25px;
    padding-top: 4px;
  }

  &:after,
  &:before {
    height: 8px;
    width: 8px;
    background-color: ${({ theme }) => theme.accent};
    transform: rotate(45deg);
    position: relative;
  }

  &:after {
    content: none;
    bottom: 4px;
  }

  &:before {
    content: ' ';
    top: 4px;
  }

  top: 85px;
  right: 120px;
  transform: rotate(15deg);

  ${media.mq[1]} {
    width: 120px;
    font-size: 1rem;

    right: 55px;
    top: 0;
    transform: rotate(-20deg);

    span {
      padding-top: 2px;
      width: 120px;
    }

    &:after {
      content: ' ';
    }

    &:before {
      content: none;
    }
  }
`

export const WithImage = ({
  className,
  image,
  onClick,
  title,
  subtitle,
}: {|
  +className?: string,
  +image: React.Node,
  +onClick?: () => void,
  +subtitle: string,
  +title: string,
|}) => {
  return (
    <WithImageWrapper className={className} onClick={onClick}>
      {image}
      <div>
        <div>{title}</div>
        <div>{subtitle}</div>
      </div>
    </WithImageWrapper>
  )
}

const WithImageWrapper: React.ComponentType<any> = styled.button`
  ${paragraph4};
  display: flex;
  align-items: center;
  text-align: left;

  border: none;
  outline: none;
  margin: 0;
  padding: 8px;

  width: 260px;
  height: 64px;

  cursor: pointer;

  background: ${({ theme }) => theme.accent};
  color: ${colors.darkPurple};

  img {
    object-fit: contain;
    height: 48px;
    width: 48px;
    margin-right: 25px;
  }
`
