// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import SwipeListener from 'swipe-listener'
import * as text from '../text'
import { hooks, Button } from '../../index'
import { Progress } from './progress'

type Props = {
  children: React.ChildrenArray<React.Element<any>>,
  title: React.Node,
  subtitle: React.Node,
  button?: React.Node,
}

export default function Main({ children, title, subtitle, button }: Props) {
  const [active, setActive] = React.useState(0)
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const swipeArea = React.useRef(null)
  const activeRef = React.useRef(active)
  const setActiveRef = (newActive) => {
    activeRef.current = newActive
    setActive(newActive)
  }
  const count = React.Children.count(children)

  React.useEffect(() => {
    const swipeAreaEl = swipeArea.current
    const swipe = (e: any) => {
      var directions = e.detail.directions

      if (directions.left) {
        setActiveRef((activeRef.current + 1) % count)
      }

      if (directions.right) {
        if (activeRef.current === 0) {
          setActiveRef(count - 1)
        } else {
          setActiveRef((activeRef.current - 1) % count)
        }
      }
    }
    SwipeListener(swipeArea.current)
    swipeAreaEl?.addEventListener('swipe', swipe)

    return () => swipeAreaEl?.removeEventListener('swipe', swipe)
  }, [count])

  const windowObj = typeof window !== 'undefined' ? window : {}

  return (
    <Root>
      <LeftSection>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <ButtonWrapper>{button}</ButtonWrapper>
        {isDesktop ? (
          <>
            <Controls>
              <Button.Circle
                onClick={() =>
                  setActiveRef(
                    (activeRef.current === 0 ? 2 : activeRef.current - 1) %
                      count
                  )
                }
                side={'left'}
              />
              <Button.Circle
                onClick={() => setActiveRef((activeRef.current + 1) % count)}
                side={'right'}
              />
            </Controls>
            <StyledProgress active={active} count={count} />
          </>
        ) : null}
      </LeftSection>
      <div ref={swipeArea}>
        <Slides active={active} screenWidth={windowObj?.screen?.availWidth}>
          {React.Children.map(children, (item, index) => (
            <Slide active={active} number={index}>
              {item}
            </Slide>
          ))}
        </Slides>
      </div>
      {!isDesktop && <StyledProgress active={active} count={count} />}
    </Root>
  )
}

const ButtonWrapper = styled.div`
  padding: 30px 0;
`

const StyledProgress = styled(Progress)`
  width: 220px;
`

const Controls = styled.div`
  display: flex;
  width: 140px;
  justify-content: space-between;
  margin-top: auto;
`

const Title = styled.span`
  ${media.mq[1]} {
    ${text.heading3};
  }
`

const Subtitle = styled.span`
  margin-top: 20px;
  ${text.paragraph3};

  ${media.mq[1]} {
    ${text.heading3};
  }
`

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 26px 20px;
  ${text.heading4};
  color: ${colors.beige};

  ${media.mq[1]} {
    min-width: 400px;
    margin-right: 30px;
    padding: 0 20px;
    padding-right: 55px;
  }
`

// because stylelint function-name-case inside css`` template string would autofix parseInt to lowercase
const parseint = Math.round

const Slide = styled.div`
  width: 21.5rem;
  max-width: 280px;
  ${media.mq[1]} {
    max-width: 345px;
  }
`

const Slides = styled.div`
  display: flex;

  ${Slide} {
    margin-right: 20px;

    &:nth-of-type(1) {
      margin-left: ${(p) =>
        parseint(
          p.active > 0 ? -280 * p.active - 20 + (p.screenWidth - 280) / 2 : 0
        )}px;
      ${media.mq[1]} {
        margin-left: 0;
      }
    }

    ${media.mq[1]} {
      &:nth-of-type(1) {
        order: ${(p) => (p.active === 0 ? '1' : p.active === 1 ? '3' : '2')};
      }
      &:nth-of-type(2) {
        order: ${(p) => (p.active === 1 ? '1' : p.active === 0 ? '2' : '3')};
      }
      &:nth-of-type(3) {
        order: ${(p) => (p.active === 2 ? '1' : p.active === 0 ? '3' : '2')};
      }
    }
  }
`

const Root: React.ComponentType<any> = styled.div`
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${media.mq[1]} {
    flex-direction: row;
    max-width: 1200px;
    overflow: visible;
  }
`
