// @flow
import { nanoid } from 'nanoid'
import * as React from 'react'

import { UnexpectedError } from '../errors'
import { useIframe } from '../lib/useIframe'

import type {
  ContactRequest,
  DemoRequest,
  Login,
  LostPasswords,
  LostPasswordsNewPassword,
  LostPasswordsToken,
  PricingPlan,
  Signup,
} from '../types'

declare var process: WebProcess

export const useAuth = () => {
  const [ready, send] = useIframe(process.env.GATSBY_TRACK_AUTH_URL)

  const auth = React.useMemo(
    () => ({
      appleSignIn: async () => {
        const response = await send({ type: 'apple:sign-in', id: nanoid() })

        if (response.type !== 'apple:sign-in') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.url
      },
      appleSignUp: async (countryId: number, planId: PricingPlan) => {
        const response = await send({
          type: 'apple:sign-up',
          id: nanoid(),
          countryId,
          planId,
        })

        if (response.type !== 'apple:sign-up') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.url
      },
      contact: async (contact: ContactRequest) => {
        const response = await send({
          type: 'contact',
          id: nanoid(),
          contact,
        })

        if (response.type !== 'contact') {
          throw new UnexpectedError('Mismatching response')
        }

        return
      },
      countries: async () => {
        const response = await send({ type: 'countries', id: nanoid() })

        if (response.type !== 'countries') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.countries
      },
      demo: async (demo: DemoRequest) => {
        const response = await send({
          type: 'demo',
          id: nanoid(),
          demo,
        })

        if (response.type !== 'demo') {
          throw new UnexpectedError('Mismatching response')
        }

        return
      },
      forgotPasswordInit: async (lostPassword: LostPasswords) => {
        const response = await send({
          type: 'forgot-password:init',
          id: nanoid(),
          lostPassword,
        })

        if (response.type !== 'forgot-password:init') {
          throw new UnexpectedError('Mismatching response')
        }

        return
      },
      forgotPasswordCheckToken: async (token: LostPasswordsToken) => {
        const response = await send({
          type: 'forgot-password:check-token',
          id: nanoid(),
          token,
        })

        if (response.type !== 'forgot-password:check-token') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.user
      },
      forgotPasswordResetPassword: async (
        password: LostPasswordsNewPassword
      ) => {
        const response = await send({
          type: 'forgot-password:reset-password',
          id: nanoid(),
          password,
        })

        if (response.type !== 'forgot-password:reset-password') {
          throw new UnexpectedError('Mismatching response')
        }

        return
      },
      googleSignIn: async (fromTogglButton?: true) => {
        const response = await send({
          type: 'google:sign-in',
          id: nanoid(),
          fromTogglButton,
        })

        if (response.type !== 'google:sign-in') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.url
      },
      googleSignUp: async (countryId: number, planId: PricingPlan) => {
        const response = await send({
          type: 'google:sign-up',
          id: nanoid(),
          countryId,
          planId,
        })

        if (response.type !== 'google:sign-up') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.url
      },
      location: async () => {
        const response = await send({ type: 'location', id: nanoid() })

        if (response.type !== 'location') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.location
      },
      login: async (login: Login) => {
        const response = await send({ type: 'login', id: nanoid(), login })

        if (response.type !== 'login') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.user
      },
      logout: async () => {
        const response = await send({ type: 'logout', id: nanoid() })

        if (response.type !== 'logout') {
          throw new UnexpectedError('Mismatching response')
        }

        return
      },
      signup: async (signup: Signup) => {
        const response = await send({
          type: 'signup',
          id: nanoid(),
          signup,
        })

        if (response.type !== 'signup') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.user
      },
      timezones: async () => {
        const response = await send({ type: 'timezones', id: nanoid() })

        if (response.type !== 'timezones') {
          throw new UnexpectedError('Mismatching response')
        }

        return response.timezones
      },
    }),
    [send]
  )

  return [ready, auth]
}
