// @flow
import styled from '@emotion/styled'
import * as React from 'react'
import { colors, media } from '@toggl/style'

type Props = {|
  children?: React.Node,
  className?: string,
  background?: string,
  center?: boolean,
  flex?: boolean,
  id?: string,
|}

export default function Block({
  children,
  background,
  center,
  flex,
  ...props
}: Props) {
  return (
    <Root {...props} background={background}>
      <Wrapper center={center} flex={flex}>
        {children}
      </Wrapper>
    </Root>
  )
}

const Root: React.ComponentType<any> = styled.div`
  padding: 60px 30px;
  background: ${(p) => p.background || colors.fadedPurple};

  ${media.mq[1]} {
    padding: 120px 30px;
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  text-align: ${(p) => (p.center ? 'center' : 'inherit')};
  ${(p) => (p.flex ? 'display: flex;' : '')}
  ${(p) => (p.flex ? 'justify-content: space-between;' : '')}
  ${(p) => (p.flex ? 'flex-direction: column;' : '')}
  ${(p) => (p.flex && p.center ? 'justify-content: space-between;' : '')}
  ${(p) =>
    p.center
      ? `h1, h2, h3 {
    text-align: center;
    }`
      : ''}

  ${(p) => (p.justifyCenter ? 'justify-content: center;' : '')}
  ${media.mq[1]} {
    max-width: 1200px;
    ${(p) => (p.flex ? 'flex-direction: row;' : '')}
  }
`
