// @flow

import React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import type { ComponentType } from 'react'
import { defaultText, paragraph2, heading5, heading4 } from '../text'

type Props = {|
  image: {
    src: string,
    alt: string,
  },
  title: string,
  paragraph: string,
|}

export default function ImageText({ image, title, paragraph }: Props) {
  return (
    <Root>
      <ImageWrapper>
        <Image src={image.src} alt={image.alt} />
      </ImageWrapper>
      <TextSection>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
      </TextSection>
    </Root>
  )
}
const ImageWrapper = styled.div`
  max-height: 225px;

  ${media.mq[1]} {
    max-height: 280px;
  }
`
const Image = styled.img`
  width: 100%;
`

const Paragraph = styled.p`
  ${paragraph2};
  color: ${colors.beige};
  margin-top: 23px;

  ${media.mq[1]} {
    margin-top: 8px;
  }
`

const TextSection = styled.div`
  padding: 28px 20px 25px 20px;
  background-color: ${colors.darkPurple};
  color: ${colors.beige};
  min-height: 200px;

  ${media.mq[1]} {
    padding: 30px 35px 50px 45px;
  }
`

const Title = styled.h5`
  ${heading5};

  padding: 4px 18px;
  background: ${colors.darkPurple};
  color: ${colors.almostWhite};
  display: inline-block;
  border-radius: 16px;

  ${media.mq[1]} {
    background: transparent;
    color: ${colors.darkPurple};
    padding: 0;
    ${heading4};
    line-height: 1.7;
  }
`

const Root: ComponentType<any> = styled.div`
  ${defaultText};
  display: flex;
  flex-direction: column;
  width: 280px;

  ${media.mq[1]} {
    width: 345px;
  }
`
