// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors } from '@toggl/style'
import type { ComponentType } from 'react'
import { transparentize } from 'polished'
import { text } from '@toggl/ui'
import { media } from '@toggl/style'
import { Icon } from '../../index'
import { css } from '@emotion/core'
export { Checkbox } from './checkbox'
export { default as Search } from './search'

type InputProps = {
  label: React.Node,
  error?: React.Node,
  id: string,
  name: string,
  onChange?: (e: SyntheticEvent<>) => void,
  onBlur?: (e: SyntheticEvent<>) => void,
  placeholder?: string,
  value?: string,
  disabled?: boolean,
}

type SelectProps = {
  children: React.Node[],
  onChange?: (e: Event) => void,
  ...InputProps,
}

export const Select = ({
  children,
  label,
  onChange,
  error,
  value,
  ...props
}: SelectProps) => {
  return (
    <LabelWrapper error={error} label={label}>
      <IconWrapper>
        <StyledSelect
          validated={value && error === null}
          onChange={(e) => onChange && onChange(e)}
          value={value}
          {...props}
        >
          {children}
        </StyledSelect>
        <Icon.CaretDown />
      </IconWrapper>
    </LabelWrapper>
  )
}

type FormProps = {
  children: React.Node[],
  onSubmit: (e: SyntheticEvent<>) => void,
  error?: string,
}

export const TwoFieldWrapper: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mq[1]} {
    flex-direction: row;
    > div:first-of-type,
    > button:first-of-type {
      margin-right: 15px;
    }
  }
`

export const Wrapper = ({ children, error, onSubmit, ...props }: FormProps) => {
  return (
    <StyledForm onSubmit={onSubmit} {...props}>
      {error && <ErrorWrapper>{error}</ErrorWrapper>}
      {children}
    </StyledForm>
  )
}

export const Input = ({
  label,
  error,
  placeholder,
  type,
  id,
  name,
  value,
  ...props
}: {
  ...InputProps,
  type: string,
}) => {
  return (
    <LabelWrapper label={label} error={error}>
      <StyledInput
        id={id}
        validated={value && error === null}
        name={name}
        type={type}
        placeholder={placeholder}
        {...props}
      />
    </LabelWrapper>
  )
}

export const LabelWrapper = ({
  children,
  label,
  error,
}: {
  children: React.Node,
  label?: React.Node,
  error?: React.Node,
}) => {
  return (
    <Root>
      <StyledLabelWrapper>{label}</StyledLabelWrapper>
      {children}
      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Root>
  )
}

export const Textarea = ({
  label,
  error,
  placeholder,
  name,
  value,
  rows,
  cols,
  ...props
}: {
  label: React.Node,
  error?: React.Node,
  placeholder?: string,
  id?: string,
  name: string,
  value?: string,
  rows?: number,
  cols?: number,
  onChange?: (e: SyntheticEvent<>) => void,
}) => {
  return (
    <LabelWrapper label={label} error={error}>
      <StyledTextarea
        validated={value && error === null}
        rows={rows || 10}
        cols={cols || 33}
        placeholder={placeholder}
        name={name}
        value={value}
        {...props}
      />
    </LabelWrapper>
  )
}

const StyledForm: ComponentType<any> = styled.form`
  input,
  select {
    width: 100%;
  }

  button {
    margin-top: 30px;
    width: 100%;
    justify-content: center;
  }
`

const ErrorWrapper = styled.div`
  padding-top: 5px;
  text-align: left;
  ${text.paragraph2};
  color: ${colors.red};
`

const StyledLabelWrapper = styled.div`
  label {
    display: block;
    text-align: left;
    ${text.paragraph2};
    color: ${(p) => p.theme.primary};

    margin-bottom: 15px;
  }
`

const validatedCss = css`
  background: ${colors.almostWhite};
  color: ${colors.darkPurple} !important;
`

const inputCss = css`
  background: transparent;
  border: 2px solid ${colors.fadedPurpleAlt};
  height: 50px;
  padding-left: 15px;

  &::placeholder {
    color: ${colors.fadedPurpleAlt};
    ${text.paragraph2};
  }

  ${text.paragraph4};
  color: ${transparentize(0.8, colors.beige)};
`

const StyledSelect = styled.select`
  ${inputCss};
  &:focus {
    border-color: ${colors.almostWhite};
    color: ${colors.almostWhite};
  }
  ${(p) => (p.validated ? validatedCss : '')};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  outline: none;
`

const StyledInput: ComponentType<any> = styled.input`
  ${inputCss};
  &:focus {
    border-color: ${colors.almostWhite};
    color: ${colors.almostWhite};
  }
  ${(p) => (p.validated ? validatedCss : '')};
`

const Root: ComponentType<any> = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 15px;

  &:first-of-type {
    margin-top: 0;
  }
`

const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  svg {
    position: absolute;
    right: 14px;
    top: 20px;
  }
`

const StyledTextarea = styled.textarea`
  ${inputCss};
  ${(p) => (p.validated ? validatedCss : '')};
`
