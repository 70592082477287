// @flow
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { text } from '@toggl/ui'

export const Wrapper = ({
  children,
}: {|
  +children: [React.Element<typeof Left>, React.Element<typeof Right>],
|}) => <CascadeWrapper>{children}</CascadeWrapper>

type ChildProps = {|
  +background?: string,
  +children: React.Node,
  +header: string | React.Node,
|}

export const Left = ({ background, children, header }: ChildProps) => (
  <LeftSection background={background}>
    <LeftHeader>{header}</LeftHeader>
    {children}
  </LeftSection>
)

export const Right = ({ background, children, header }: ChildProps) => (
  <RightSection background={background}>
    <RightHeader>{header}</RightHeader>
    {children}
  </RightSection>
)

const CascadeWrapper: React.ComponentType<any> = styled.div`
  ${media.mq[1]} {
    padding-top: 120px;
    position: relative;
    margin: 0 auto;
    width: 1200px;
    height: 920px;
  }
`

const LeftSection: React.ComponentType<any> = styled.div`
  background: ${({ background, theme }) => background || theme.accent};
  padding: 51px 20px 56px 20px;
  color: ${colors.darkPurple};

  ${media.mq[1]} {
    background: ${({ theme }) => theme.primary};
    width: 710px;
    padding: 60px 100px;
    z-index: 1;
    position: relative;
  }
`

const LeftHeader = styled.div`
  ${text.heading3};
  width: 200px;
  margin: 0 auto;
  margin-bottom: 33px;

  color: ${colors.darkPurple};

  ${media.mq[1]} {
    ${text.heading2};
    color: ${colors.darkPurple};
    width: 330px;
    margin: 0;
    margin-bottom: 40px;
  }
`

const RightSection: React.ComponentType<any> = styled.div`
  display: none;

  ${media.mq[1]} {
    width: 588px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.darkPurple};
    color: ${({ theme }) => theme.accent};
    padding: 76px 126px 80px 165px;

    transform: translateY(-300px) translateX(600px);
  }
`

const RightHeader = styled.div`
  ${text.heading3};

  margin-bottom: 38px;
`
