// @flow
import type { ComponentType } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'

import { Icon } from '../../index'
import * as text from '../text'
import { colors, media } from '@toggl/style'

type Props = {
  goBackLink: string,
  title: string,
}
export function BackSocial({ goBackLink, title }: Props) {
  const theme = useTheme()

  return (
    <Root>
      <Header>
        <div>
          <Link href={goBackLink}>
            <Icon.ArrowBigLeft color={theme.primary} />

            <HeaderText>All job openings</HeaderText>
          </Link>
        </div>
        <DesktopSubHeaderContent>
          <SocialIcons />
        </DesktopSubHeaderContent>
      </Header>

      <MobileSubHeaderContent>
        <P>Working at Toggl</P>

        <PositionTitle>{title}</PositionTitle>

        <SocialIcons />
      </MobileSubHeaderContent>
    </Root>
  )
}

const SocialIcons = () => {
  const shareUrl = 'https://toggl.com/jobs'

  return (
    <SocialIconsStyled>
      <P>Share the love</P>

      <div>
        <Link
          target="_blank"
          href={getTwitterLink(
            'Great news! @toggl is hiring remotely for your dream job! Check it out:',
            shareUrl
          )}
        >
          <Icon.Twitter />
        </Link>

        <Link target="_blank" href={getFacebookLink(shareUrl)}>
          <Icon.Fb />
        </Link>

        <Link
          target="_blank"
          href={getLinkedinLink(
            'Toggl is hiring remotely for your dream job!',
            shareUrl
          )}
        >
          <Icon.LinkedIn />
        </Link>
      </div>
    </SocialIconsStyled>
  )
}

export const H: ComponentType<any> = styled.h2`
  ${text.heading2};
  color: ${colors.beige};
`

export const P: ComponentType<any> = styled.span`
  ${text.paragraph3};
  color: ${colors.beige};
`

export const Link: ComponentType<any> = styled.a`
  cursor: pointer;

  svg {
    width: 32px;
  }
`

export const PositionTitle: ComponentType<any> = styled(H)`
  width: 249px;
  text-align: center;
  margin-top: 13px;
`

export const SocialIconsStyled: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  margin-top: 20px;

  div {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
  }

  ${media.mq[1]} {
    flex-direction: row;
    justify-content: space-between;
    width: 244px;
    margin: 0;

    div {
      width: 110px;
      margin: 0;
    }
  }
`

export const DesktopSubHeaderContent: ComponentType<any> = styled.div`
  display: none;

  ${media.mq[1]} {
    display: flex;
  }
`

export const MobileSubHeaderContent: ComponentType<any> = styled.div`
  background-color: ${colors.darkPurple};
  height: 200px;
  padding-top: 26px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mq[1]} {
    display: none;
  }
`

export const HeaderText: ComponentType<any> = styled(P)`
  margin-left: 20px;
  color: ${colors.beige};
`

export const Header: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 27px 23px;
  border-bottom: 1px solid ${colors.fadedPurple};

  ${media.mq[1]} {
    border-bottom: none;
    padding: 0;
    justify-content: space-between;
  }
`

export const Root: ComponentType<any> = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${colors.darkPurple};

  ${media.mq[1]} {
    padding: 64px 122px 49px 122px;
  }
`
const getTwitterLink = (text: string, url: string) => {
  const twitterLink = new URL('https://twitter.com/intent/tweet')
  twitterLink.searchParams.set('text', text)
  twitterLink.searchParams.set('url', url)
  return twitterLink.href
}

const getFacebookLink = (url: string) => {
  const facebookLink = new URL('https://www.facebook.com/sharer/sharer.php')
  facebookLink.searchParams.set('u', url)
  return facebookLink.href
}

const getLinkedinLink = (text: string, url: string) => {
  const linkedinLink = new URL('https://www.linkedin.com/shareArticle')
  linkedinLink.searchParams.set('mini', 'true')
  linkedinLink.searchParams.set('title', text)
  linkedinLink.searchParams.set('url', url)
  return linkedinLink.href
}
