// @flow
import * as React from 'react'
import { useFormik } from 'formik'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import { Icon, text } from '@toggl/ui'

type Props = {|
  +className?: string,
  +initialValue?: string,
  +placeholder: string,
  +onSearch: (string) => void,
|}

export default function Search({
  className,
  initialValue = '',
  placeholder,
  onSearch,
}: Props) {
  const formik = useFormik({
    initialValues: {
      search: initialValue,
    },
    onSubmit: ({ search }) => {
      if (search) onSearch(search)
    },
    enableReinitialize: true,
  })

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          formik.handleSubmit()
        }
      }}
      onSubmit={formik.handleSubmit}
    >
      <Wrapper className={className}>
        <Input
          id="search"
          name="search"
          placeholder={placeholder}
          onChange={formik.handleChange}
          value={formik.values.search}
        />
        <SubmitButton type="submit" hasValue={!!formik.values.search}>
          <Icon.Search />
        </SubmitButton>
      </Wrapper>
    </form>
  )
}

const SubmitButton: React.ComponentType<any> = styled.button`
  border: none;
  outline: none;
  margin: 0;
  padding: 0;

  cursor: ${({ hasValue }) => (hasValue ? 'pointer' : 'default')};

  background: transparent;

  margin-right: 30px;
  ${media.mq[2]} {
    margin-right: 40px;
  }

  svg {
    path {
      fill: ${colors.darkPurple};

      ${media.mq[2]} {
        fill: ${colors.fadedPurpleAlt};
      }
    }
  }
`

const Wrapper: React.ComponentType<any> = styled.div`
  display: flex;
  width: 100%;
  height: 64px;

  background-color: white;

  ${media.mq[2]} {
    height: 80px;
  }
`

const Input: React.ComponentType<any> = styled.input`
  height: 100%;

  outline: none;
  border: none;
  flex-grow: 1;

  ${text.paragraph4};
  color: ${colors.darkPurple};

  ::placeholder {
    ${text.paragraph4};
    color: ${colors.darkPurple};
  }

  padding-left: 30px;
  padding-right: 30px;

  ${media.mq[2]} {
    ${text.paragraph2};
    color: ${colors.fadedPurpleAlt};

    ::placeholder {
      ${text.paragraph2};
      color: ${colors.fadedPurpleAlt};
    }

    padding-left: 40px;
  }
`
