// @flow

import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import * as React from 'react'
import styled from '@emotion/styled'

import { Button, Footer, Navigation, Icon } from '@toggl/ui'
import { themes, colors } from '@toggl/style'
import { Link } from 'gatsby'
import type { NavigationType } from '../types'

// $FlowFixMe
import yamlData from '../../data/global/layout.yml'

type Props = {
  Hero: React.ComponentType<any>,
  children?: React.Node,
  background?: string,
}

const globalWidth = css`
  width: 85px;
  svg {
    width: 100%;
  }
`
const IconGlobal = () => {
  return (
    <div css={globalWidth}>
      <Icon.Global />
    </div>
  )
}
const LayoutGlobal = ({ children, Hero, background }: Props) => {
  const navigation: NavigationType = yamlData.navigation

  return (
    <ThemeProvider theme={themes.global}>
      <Hero>
        <Navigation.Header
          background={background}
          contents={{
            cta: (
              <Navigation.MenuContent
                title={navigation.cta.title}
                description={navigation.cta.subtitle}
              >
                <Navigation.ProductsGroup>
                  {navigation.cta.items?.map((item, index) => {
                    return (
                      <Link key={index} to={item.url}>
                        <Navigation.Product
                          key={index}
                          // $FlowFixMe[unsupported-syntax]
                          img={require(`assets/uploads/${item.image}`)}
                          title={item.title}
                          description={item.subtitle}
                        />
                      </Link>
                    )
                  })}
                </Navigation.ProductsGroup>
              </Navigation.MenuContent>
            ),
            products: (
              <Navigation.MenuContent
                title={navigation.products.title}
                description={navigation.products.subtitle}
              >
                <Navigation.ProductsGroup>
                  {navigation.products.items?.map((item, index) => {
                    return (
                      <Link key={index} to={item.url}>
                        <Navigation.Product
                          key={index}
                          // $FlowFixMe[unsupported-syntax]
                          img={require(`assets/uploads/${item.image}`)}
                          title={item.title}
                          description={item.subtitle}
                        />
                      </Link>
                    )
                  })}
                </Navigation.ProductsGroup>
              </Navigation.MenuContent>
            ),
            login: (
              <Navigation.MenuContent
                title={navigation.login.title}
                description={navigation.login.subtitle}
              >
                <Navigation.ProductsGroup>
                  {navigation.login.items?.map((item, index) => {
                    return (
                      <Link key={index} to={item.url}>
                        <Navigation.Product
                          // $FlowFixMe[unsupported-syntax]
                          img={require(`assets/uploads/${item.image}`)}
                          title={item.title}
                          description={item.subtitle}
                        />
                      </Link>
                    )
                  })}
                </Navigation.ProductsGroup>
              </Navigation.MenuContent>
            ),
          }}
          Logo={({ ...props }) => (
            <Link to={'/'}>
              <Icon.Global {...props} />
            </Link>
          )}
          login={
            <Button.Secondary color={colors.beige}>Login</Button.Secondary>
          }
          Cta={() => (
            <Button.Primary data-expandable="true">Try Toggl</Button.Primary>
          )}
        >
          <a data-expandable="true" data-content-key="products">
            Products
          </a>
          <a href={'https://toggl.com/blog'} rel="noreferrer" target="_blank">
            Blog
          </a>
          <Link to={'/mission'}>Our mission</Link>
          <Link to={'/jobs'}>Working at Toggl</Link>
        </Navigation.Header>
      </Hero>

      <MainContent>
        <Body>{children}</Body>
        <Footer.Wrapper
          Link={Link}
          logo={<IconGlobal />}
          products={[
            <>
              <Link to={'/track/'} key="track">
                <Icon.Track />
                <span>Effortless time tracking</span>
              </Link>
            </>,
            <>
              <a
                href="https://toggl.com/plan/"
                key="plan"
                rel="noreferrer"
                target="_blank"
              >
                <Icon.Plan />
                <span>Beautifully simple project planning</span>
              </a>
            </>,
            <>
              <a
                href="https://toggl.com/hire/"
                key="hire"
                rel="noreferrer"
                target="_blank"
              >
                <Icon.Hire />
                <span>Intelligent candidate screening</span>
              </a>
            </>,
          ]}
        />
      </MainContent>
    </ThemeProvider>
  )
}

const MainContent = styled.div``
const Body = styled.div``

export default LayoutGlobal
