// @flow
import * as React from 'react'
import styled from '@emotion/styled'

import { colors, media } from '@toggl/style'
import * as text from '../text'

type Props = {|
  +className?: string,
  +description: string,
  +image: React.Node,
  +label?: string,
  +title: string,
|}

export default function ImageTextSimple({
  className,
  description,
  image,
  label,
  title,
}: Props) {
  return (
    <Root className={className}>
      <ImageWrapper>
        {label && <Label>{label}</Label>}
        {image}
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </Root>
  )
}

const Root: React.ComponentType<any> = styled.div`
  display: flex;

  ${media.mq[2]} {
    flex-direction: column;
    width: 350px;
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  background-color: white;

  width: 140px;
  height: 90px;

  ${media.mq[2]} {
    width: 345px;
    height: 240px;
    position: relative;
  }

  img {
    object-fit: contain;
  }
`

const TextWrapper = styled.div`
  margin-left: 10px;
  align-self: center;

  ${media.mq[2]} {
    margin-left: 0;
    margin-top: 15px;
    align-self: flex-start;
  }

  span {
    display: block;
  }
`

const Title = styled.span`
  ${text.heading5};

  ${media.mq[1]} {
    ${text.heading4};
  }

  color: ${colors.darkPurple} !important;
  margin-bottom: 5px;

  ${media.mq[2]} {
    margin-bottom: 10px;
  }
`

const Description = styled.span`
  ${text.paragraph4};
  font-size: 0.75rem;
  color: ${colors.fadedPurpleAlt};

  ${media.mq[1]} {
    font-size: 0.875rem;
  }
`

const Label = styled.div`
  display: none;

  ${media.mq[2]} {
    ${text.paragraph4};
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${({ theme }) => theme.accent};
    color: ${colors.darkPurple};

    position: absolute;
    top: 5px;
    right: 5px;

    width: 80px;
    height: 32px;
    border-radius: 16px;
  }
`
