// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { colors, media } from '@toggl/style'
import * as hooks from '../hooks'

export const Wrapper: React.ComponentType<any> = styled.div`
  transform: skewY(10deg);
  background: ${(p) => p.background || colors.beige};
  overflow: hidden;
  position: relative;
  z-index: 1;

  > div {
    transform: skewY(-10deg);
  }
`

export const Content: React.ComponentType<any> = styled.div`
  margin: 0 auto;
  display: flex;

  padding: 50px 30px;
  flex-direction: column;

  ${media.mq[1]} {
    max-width: 1200px;
    padding: 100px 0;
    flex-direction: row;
  }
`

const BackgroundStyled: React.ComponentType<any> = styled.div`
  background: ${(p) => p.background || colors.beige};
  position: relative;
  padding-bottom: ${(p) => `${p.sideHeight}px` || 0};
  padding-top: ${(p) => `${p.sideHeight}px` || 0};
`

export const Background = ({
  children,
  background,
  ...props
}: {
  children: React.Node,
  background: string,
}) => {
  const width = hooks.useCurrentWidth()
  const sideHeight = (width * Math.tan((10 * Math.PI) / 180)) / 2
  return (
    <BackgroundStyled
      sideHeight={sideHeight}
      background={background}
      {...props}
    >
      {children}
    </BackgroundStyled>
  )
}

// export const LowerBackground: React.ComponentType<any> = styled.div`
//   width: 100%;
//   height: 200px;
//   color: ${(p) => p.background || colors.darkPurple};
//   position: absolute;
//   bottom: 0;
// `
